.Toastify__toast {
  min-height: 56px !important;
  border: 1px solid !important;
  border-radius: 8px !important;
  font-weight: 400 !important;

  svg {
    width: 22px !important;
  }

  &-body {
    padding: 0 !important;
  }
  &-icon {
    width: fit-content !important;
  }

  &--success {
    border-color: rgba(26, 192, 87, 1) !important;
    color: rgba(26, 192, 87, 1) !important;
  }
  &--info {
    border-color: rgba(94, 153, 245, 1) !important;
    color: rgba(94, 153, 245, 1) !important;
  }
  &--warning {
    border-color: rgba(242, 201, 76, 1) !important;
    color: rgba(242, 201, 76, 1) !important;
  }
  &--error {
    border-color: rgba(226, 118, 37, 1) !important;
    color: rgba(226, 118, 37, 1) !important;
  }
  &--default {
    border-color: rgba(94, 153, 245, 1) !important;
    color: rgba(94, 153, 245, 1) !important;
  }
}

.Toastify__close-button {
  align-self: center !important;
  width: 25px !important;
  height: 25px !important;
}
